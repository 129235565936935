
require('normalize.css/normalize.css');
require('./styles/index.scss');
require('./js/scroll-converter.min.js')

// var isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);
// if (!isMobile) {
    window.scrollConverter.activate();
//}


document.addEventListener("DOMContentLoaded", () => {


    



    // $('a.jump').bind('click', (e) => {
    //     e.preventDefault(); // prevent hard jump, the default behavior
    //     jumpMenu(e);
    // });


    // menu
    const hamb = document.querySelector('.hamburger');
    const menu = document.querySelector('.menu');

    const nav = document.querySelectorAll('.nav');
    const section = document.querySelectorAll('.section');

    const toggleMenu = (e) => {
        e.preventDefault();
        hamb.classList.toggle('is-active');
        menu.classList.toggle('toggle');
        document.querySelector('.body').classList.toggle('blur');
    }


    hamb.addEventListener('click', toggleMenu);
    hamb.addEventListener('touchstart', toggleMenu);


    //capture fb event for contact
    const phone = document.querySelectorAll('.call_btn');
    const form = document.querySelectorAll('.contact_btn');
    
    const trackContact = () => {
        fbq('track', 'Contact');
    }

    phone.addEventListener('click', trackContact);
    phone.addEventListener('touchstart', trackContact);

    form.addEventListener('click', trackContact);
    form.addEventListener('touchstart', trackContact);
        

    


//--
    // const pluginsTriggerElement = document.getElementById('plugins-trigger');
    // const pluginsElement = document.getElementById('plugins');

    // const pluginsVisibleClass = "splash-overview-plugins__list--visible";

    // pluginsTriggerElement.onclick = () => {
    //     pluginsElement.classList.toggle(pluginsVisibleClass);
    // }
//--
    

    // const jumpMenu = (e) => {
    //     e.preventDefault();
    //     const target = e.currentTarget.getAttribute('href');
    //     const targetName = target.slice(1);
    //     section.forEach(sectionItem => sectionItem.classList.remove('visible'));

    //     document.getElementById(targetName).classList.add("visible");

    //     window.history.pushState(
    //         '',
    //         '',
    //         `${window.location.pathname + target}`
    //     );

    //     if (window.innerWidth <= 1024) {
    //         toggleMenu();
    //     }
    // }

    // nav.forEach( navItem => {
    //     navItem.onclick = (e) => {
    //         jumpMenu(e); 
    //     }
    // });

    let i;

    // let corporate = '';
    // for (i = 1; i <= 13; i++) {
    //     const myImage = require(`./images/corporate/${i}.jpg`);
    //     corporate = corporate + `<img src="${myImage}">`;
    // }
    // document.getElementById('business_photos').innerHTML = corporate;


    // let families = '';
    // for (i = 1; i <= 12; i++) {
    //     const myImage = require(`./images/families/${i}.jpg`);
    //     families = families + `<img src="${myImage}">`;
    // }
    // document.getElementById('family_photos').innerHTML = families;


    // let couples = '';
    // for (i = 1; i <= 10; i++) {
    //     const myImage = require(`./images/couples/${i}.jpg`);
    //     couples = couples + `<img src="${myImage}">`;
    // }
    // document.getElementById('couples_photos').innerHTML = couples;
    


    // let weddings = '';
    // for (i = 1; i <= 15; i++) {
    //     const myImage = require(`./images/wedding/${i}.jpg`);
    //     weddings = weddings + `<img src="${myImage}">`;
    // }
    // for (i = 23; i <= 32; i++) {
    //     const myImage = require(`./images/wedding/${i}.jpg`);
    //     weddings = weddings + `<img src="${myImage}">`;
    // }
    // document.getElementById('wedding_photos').innerHTML = weddings;


    // let tourism = '';
    // for (i = 1; i <= 10; i++) {
    //     const myImage = require(`./images/tourism/${i}.jpg`);
    //     tourism = tourism + `<img src="${myImage}">`;
    // }
    // document.getElementById('tourism_photos').innerHTML = tourism;
});
